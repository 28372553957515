/*---------------------------------------------------------------------------*\
 |  Player.css                                                               |
\*---------------------------------------------------------------------------*/

.Player video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  opacity: 0.4;
  transition: all 0.2s ease 0s;
}

.Player video:not(:first-of-type) {
  display: none;
}

.Player video.Playing {
  opacity: 0.8;
  cursor: pointer;
}

.Player video.NotPlaying {
  opacity: 0.4;
  cursor: auto;
}

.Player figure {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 24px;
  width: 20em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.25;
  opacity: 0.0;
  transition: all 0.2s ease 0s;

  display: none;
}

@media (min-width: 992px) {
  .Player figure {
    display: block;
  }
}

.Player figure.Playing {
  opacity: 1.0;
}

.Player figure.NotPlaying {
  opacity: 0.0;
}
