/*---------------------------------------------------------------------------*\
 |  Logo.css                                                                 |
\*---------------------------------------------------------------------------*/

.Logo {
  position: absolute;
  margin: 24px;
}

.Logo.WTF {
  position: fixed;
  right: 0;
  bottom: 0;
}

@media (min-width: 992px) {
  .Logo.Home {
    position: fixed;
    right: 0;
    bottom: 0;
  }
}

.Logo img {
  height: 54px;
}

@media (min-width: 992px) {
  .Logo img {
    height: 72px;
  }
}
