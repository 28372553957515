/*---------------------------------------------------------------------------*\
 |  Search.css                                                               |
\*---------------------------------------------------------------------------*/

.Search {
  position: fixed;
  margin: 24px;
  width: 20em;
  font-weight: 300;
}

.Search fieldset {
  margin: 0;
  border: 0;
  padding: 0;
}

.Search input {
  -webkit-appearance: none !important;
  border: 1px solid white;
  border-radius: 2px;
  padding: 4px 8px;
  width: 6em;
  background: transparent;
  color: white;
  line-height: 1.25;
  font-size: 18px;
  font-weight: 700;
  text-transform: lowercase;
  transition: all 0.2s ease 0s;
}

@media (min-width: 992px) {
  .Search input {
      font-size: 24px;
  }
}

.Search input::-webkit-input-placeholder {
  font-weight: 300;
}

.Search input::-moz-placeholder {
  font-weight: 300;
}

.Search input::-ms-input-placeholder {
  font-weight: 300;
}

.Search input:focus {
  outline: 0;
  width: 20em;
}

.Search ol {
  padding: 0 9px;
}

.Search li {
  margin: 0.5em 0;
  list-style-type: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.25;
}

.Search li:last-of-type {
  margin: 0;
}

.Search a {
  text-decoration: none;
}
