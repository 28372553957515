/*---------------------------------------------------------------------------*\
 |  index.css                                                                |
\*---------------------------------------------------------------------------*/

/* Inline http://fonts.googleapis.com/css?family=Lato:300,400,700 */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"), url("https://themes.googleusercontent.com/static/fonts/lato/v7/nj47mAZe0mYUIySgfn0wpQ.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"), url("https://themes.googleusercontent.com/static/fonts/lato/v7/v0SdcGFAl2aezM9Vq_aFTQ.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"), url("https://themes.googleusercontent.com/static/fonts/lato/v7/DvlFBScY1r-FMtZSYIYoYw.ttf") format("truetype");
}

html, body {
  max-width: 100vh;
  overflow-x: hidden;
}

body {
  margin: 0;
  border: 0;
  padding: 0;
  background: black;
  color: white;
  font: 18px "Lato", sans-serif;
  text-transform: lowercase;
}

@media (min-width: 992px) {
  body {
    font-size: 24px;
  }
}

a {
  color: white;
}

em {
  font-style: normal;
  font-weight: 700;
}
